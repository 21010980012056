/* RoyalSalute.css */
body {
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
    background: black;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/bgStars.jpg'); /* Ensure this path is correct */
    background-size: cover;
    background-position: center;
}
  
.royal  {
    width: 100%;
    max-width: 450px;
    background-image: url('../images/bg-v102.png'); /* Ensure this path is correct */
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    text-align: center;
}
  
h1 {
    font-size: 18px;
    color: rgb(255, 255, 255);
    margin-top: 120px;
    text-align: center;
}
  
.image-container {
    position: relative;
    margin: 20px 0;
}

.image-container img {
   height: 200px; /* Corrected property name for maximum width */
}

.image-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    width: 30px; /* Set a specific width for buttons */
    height: 30px; /* Set a specific height for buttons */
}
  
.image-nav img {
    width: 100%; /* Ensure the button images fill the button size */
    height: auto;
}

.image-nav.left {
    left: 0px; /* Adjust if necessary */
    width: 50px;
}

.image-nav.right {
    right: 0px; /* Adjust if necessary */
    width: 50px;
}
  
.description, .details {
    text-align: left; /* Left-align text in these sections */
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent black background */
    color: white; /* Ensures text is white for better readability */
    border-radius: 5px; /* Optionally, add rounded corners */
    margin-bottom: 10px; /* Adds space between sections and other elements */
}

  
.description h2, .details h2 {
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-top: 5px;
    text-align: center; /* Keep headings centered */
    padding: 5px;
}
  
.description p, .details p {
    font-size: 14px;
    color: #ccc;
}
  
.details p {
    margin: 5px 0;
}
  




