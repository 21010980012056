

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.top {
  flex: 1;
}

.middle {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.middle .box {
  background-color: rgba(255, 255, 255, 0.2); /* สีพื้นหลังโปร่งใส */
  padding: 20px;
  border-radius: 25px;
  text-align: left; /* จัดข้อความชิดซ้าย */
  width: 90%; /* ตั้งความกว้างของกล่อง */
  margin: 20px; /* เพิ่มระยะห่างระหว่างกล่องกับขอบหน้าจอ */
}

.middle .box .group1 {
  margin-bottom: 10px; /* เพิ่มระยะห่างระหว่างกลุ่ม */
}

.middle .box p {
  margin: 0;
  padding: 5px 0;
  font-size: 20px;
}

.middle .box .image-containers {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* ซ่อนส่วนที่เกินของภาพ */
  margin-bottom: 20px; /* เพิ่มระยะห่างระหว่างภาพและข้อความ */
}

.middle .box .image-containers img {
  width: 60%;
  object-fit: contain; /* รักษาสัดส่วนภาพและให้ขนาดพอดีกับคอนเทนเนอร์ */
}

.bottom {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottom p {
  font-size: 18px;
  margin-bottom: 10px;
  color: rgb(228, 228, 223);
}

.bottom .px {
  margin-bottom: -10px;
}

.bottom .store-links {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 120px;
}

.bottom img {
  width: 150px;
  height: auto;
}

.footer {
  padding: 10px;

  bottom: 0;
  font-size: 14px;
  color: rgb(255, 255, 255);
  text-align: center; /* จัดข้อความให้อยู่ตรงกลาง */

}
